import React, { Component, Fragment } from "react";
import Layout from "../components/layout";
import { navigate } from '@reach/router';

import PropTypes from 'prop-types'
import Header from "../components/Header";
import styled from "styled-components"
import queryString from 'query-string'
import Text, {Section, Grid} from "../components/Theme";
import { AnimatePresence, motion } from "framer-motion";
import Close from "../components/Header/close";
import media from "styled-media-query";
import PrintUnavailable from "../components/no-print.js";
// import { motion } from "framer-motion";

import { graphql, Link } from "gatsby";

import Img from "gatsby-image";

import Footer from "../components/footer.js";

const CloseBtn = styled.div`
    position: fixed;
    top: 40px;
    right: 40px;
    g {
        fill: #fff !important;
    }
    ${media.greaterThan("medium")`
        top: 140px;
        right: 140px;
    `}
`;
const ArticleLink = styled.span`
    margin-top: 10px;
    display: block;
    a {
        text-decoration: underline;
    }
`;

const PrintModal = styled.div`
    position: fixed;
    background-color: #fff;
    padding: 0;
    z-index: 9997;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${media.greaterThan("medium")`
        padding: 100px;
    `}
`;
const DailyFlex = styled.div`
    display: block;

    ${media.greaterThan("medium")`
        ${Grid};
        align-items: start;
        padding-top: 20vh;
        padding-bottom: 20vh;
        &:first-child {
            padding-top: 0;
        }
    `}

`

const OverviewContent = styled.div`
    width: 100%;
    padding: 25px;
    margin-bottom: 60px;
    ${media.greaterThan("medium")`
        grid-column-start: 8;
        width: 440px;

    `}
`;


const OverviewImg = styled.div`

    max-width: 100%;
    display: block;
    width: 100%;

    ${media.greaterThan("medium")`
        align-items: center;
        grid-column: 2/8;
        padding-right: 0;
        padding-left: 0;
    `}
`
const SubscribeBox = styled.div`
    grid-column: 2/11;
    padding: 90px 0 50px 0;

    input {
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;
        width: 100%;
        &::placeholder {
            opacity: 0.3;
        }
    }
`;


class DailyPage extends Component {
    state = {
        unavailable: false,
        hovered: false,
        printName: {},
        showLightbox: false,
        selectedImage: 0,
        imgId: false,
    };
    

    componentDidMount = () => {
        window.addEventListener('keyup', this.handleKeyUp, false)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keyup', this.handleKeyUp, false)
    }

    handleClick = (e, index) => {
        e.preventDefault()
        this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
    }

    closeLightbox = parameter => {
        
        this.setState({ showLightbox: false })
        navigate('#' + parameter);

    }

    goBack = () => {
        this.setState({ selectedImage: this.state.selectedImage - 1 })
    }

    goForward = () => {
        this.setState({ selectedImage: this.state.selectedImage + 1 })
    }

    handleKeyUp = e => {
        e.preventDefault()
        const { keyCode } = e
        if (this.state.showLightbox) {
            if (keyCode === 37) {
                // Left Arrow Key
                if (this.state.selectedImage > 0) {
                    this.setState({ selectedImage: this.state.selectedImage - 1 })
                }
            }
            if (keyCode === 39) {
                // Right Arrow Key
                if (this.state.selectedImage < 19) {
                    this.setState({ selectedImage: this.state.selectedImage + 1 })
                }
            }
            // if (keyCode === 27) {
            //     // Escape key
            //     this.setState({ showLightbox: false })
            // }
        }
    }

    setHover = () => this.setState({ hovered: true });
    cancelHover = () => this.setState({ hovered: false });

    openModal = parameter => {
        const { unavailable } = this.state;
        if (!unavailable) {
            this.setState({
                unavailable: true,
                printName: parameter
            });
        }
    };

    closeModal = () => {
        const { unavailable } = this.state;
        if (unavailable) {
            this.setState({ unavailable: false });
        }
    };

    render() {
        
        
        const { showLightbox, selectedImage } = this.state
        const facebookUrl =
            "https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/overviews/";

        const {prevPageSuffix, nextPageSuffix, overviews, firstIndex,
               lastIndex, totalCount} = this.props.pageContext;

        return (
            <Fragment>
                <Layout parent="daily-page">
                    <Header content_color="black" />
                    <Section>
                        <SubscribeBox>
                            <Text sub>
                                To receive our daily post and the occasional
                                announcement:
                            </Text>
                            <Text sub>
                                <form
                                    action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                    method="post"
                                    id="revue-form"
                                    name="revue-form"
                                    target="_blank"
                                >
                                    <input
                                        className="revue-form-field"
                                        placeholder="Enter your email and hit enter to subscribe &#8594;"
                                        type="email"
                                        name="member[email]"
                                        id="member_email"
                                    />
                                    <input
                                        type="submit"
                                        value=""
                                        name="member[subscribe]"
                                        id="member_submit"
                                    ></input>
                                </form>
                            </Text>
                        </SubscribeBox>
                    </Section>
                    <div>
                        {overviews.map(({ node: overview }, i) => (
                            <DailyFlex key={overview.id} id={overview.id}>
                                <OverviewImg
                                    gutter={overview.image.fluid.aspectRatio}
                                >
                                    <a
                                        href={overview.image.fluid.src}
                                        alt="Car Image"
                                        onClick={e => this.handleClick(e, i)}
                                    >
                                        <Img
                                            fluid={overview.image.fluid}
                                            alt={overview.title}
                                            style={{ backgroundColor: "black" }}
                                            durationFadeIn={500}
                                            placeholderStyle={
                                                {
                                                    // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                                                    // "boxShadow": "0 0 100px 200px inset black",
                                                }
                                            }
                                        />
                                    </a>
                                </OverviewImg>

                                <OverviewContent>
                                    <h1
                                        id="w-node-0e4a4f7ac915-5940d611"
                                        className="subheader daily-header"
                                    >
                                        {overview.title}
                                    </h1>
                                    <p className="body-copy-small overview-description">
                                        {overview.description}
                                        {overview.articleLink && (
                                            <ArticleLink>
                                                <Link
                                                    to={`/stories/${overview.articleLink.slug}`}
                                                >
                                                    Read the full story &rarr;
                                                </Link>
                                            </ArticleLink>
                                        )}
                                    </p>
                                    <p className="caption overview-geo">
                                        {overview.geo.latitude.toFixed(6)}°,
                                        {overview.geo.longitude.toFixed(6)}°
                                        <br />
                                    </p>
                                    <p className="caption overview-source">
                                        {overview.source}
                                        <br />
                                    </p>
                                    <div className="meta-btns">
                                        {overview.shopLink && (
                                            <Link
                                                to={overview.shopLink}
                                                className="caption daily-links"
                                            >
                                                Shop
                                            </Link>
                                        )}

                                        {!overview.shopLink && (
                                            <p
                                                onClick={this.openModal.bind(
                                                    this,
                                                    overview.title
                                                )}
                                                className="caption daily-links shop"
                                            >
                                                Shop
                                            </p>
                                        )}

                                        <p className="caption daily-links">
                                            <a
                                                href={`mailto:?subject=Check out this post on Overview&body=Check out the ${overview.title} post on Overview: https://www.over-view.com/overviews/${overview.slug}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                Email
                                            </a>
                                            |
                                            <a
                                                href={
                                                    facebookUrl + overview.slug
                                                }
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                FB
                                            </a>
                                        </p>
                                    </div>
                                </OverviewContent>
                            </DailyFlex>
                        ))}
                        <LightboxModal
                            visible={showLightbox}
                            onKeyUp={e => this.handleKeyUp(e)}
                        >
                            <LightboxContent>
                                <AnimatePresence>
                                    <motion.div
                                        key={`${overviews[selectedImage].node.image.fluid}`}
                                        animate={{ scale: 1 }}
                                        transition={{ duration: 2.5 }}
                                        exit={{ scale: 0 }}
                                    >
                                        <Img
                                            fluid={
                                                overviews[selectedImage].node
                                                    .image.fluid
                                            }
                                            fit="contain"
                                        />
                                    </motion.div>
                                </AnimatePresence>
                                <Controls>
                                    <CloseButton
                                        onClick={this.closeLightbox.bind(
                                            this,
                                            overviews[selectedImage].node.id
                                        )}
                                    >
                                        &#10005;
                                    </CloseButton>

                                    <LeftRight>
                                        <LeftButton
                                            className={
                                                selectedImage === 0
                                                    ? "inactive"
                                                    : "active"
                                            }
                                            onClick={this.goBack}
                                            disabled={selectedImage === 0}
                                        >
                                            &#8592;
                                        </LeftButton>
                                        <RightButton
                                            className={
                                                selectedImage > 19
                                                    ? "inactive"
                                                    : "active"
                                            }
                                            onClick={this.goForward}
                                            disabled={selectedImage > 19}
                                        >
                                            &#8594;
                                        </RightButton>
                                    </LeftRight>
                                </Controls>
                            </LightboxContent>
                        </LightboxModal>
                        <DailyFlex>
                            <div
                                id="w-node-ee18aaf4fbb3-c6b3f613"
                                className={`pagination-prev ${prevPageSuffix ===
                                    null && "inactive"}`}
                            >
                                {prevPageSuffix !== null ? (
                                    <Link
                                        to={`/daily/${prevPageSuffix}`}
                                        className="nav-menu-link"
                                    >
                                        Previous
                                    </Link>
                                ) : (
                                    "Previous"
                                )}
                            </div>
                            <div
                                id="w-node-e4d10996b7f3-c6b3f613"
                                className={`pagination-prev ${nextPageSuffix ===
                                    null && "inactive"}`}
                            >
                                {nextPageSuffix !== null ? (
                                    <Link
                                        to={`/daily/${nextPageSuffix}`}
                                        className="nav-menu-link"
                                    >
                                        Next
                                    </Link>
                                ) : (
                                    "Next"
                                )}
                            </div>
                            <div
                                id="w-node-df55a5fcb5ad-c6b3f613"
                                className="pagination-number"
                            >
                                {firstIndex + 1} – {lastIndex} ({totalCount})
                            </div>
                        </DailyFlex>
                    </div>
                    <Footer />
                    {this.state.unavailable ? (
                        <PrintModal>
                            <PrintUnavailable name={this.state.printName} />
                            <CloseBtn onClick={this.closeModal}>
                                <Close />
                            </CloseBtn>
                        </PrintModal>
                    ) : null}
                </Layout>
            </Fragment>
        );
    }
}

const StyledImg = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }

  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

const GalleryItem = styled.div`
  position: relative;
`

const CloseButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: 1;
    position: absolute;
    top: 20px;
    right: 20px;   
    z-index: 9999;
`
const LeftButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: .2;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 20px;    
    &.active {
        opacity: 1;
    }
    `
const RightButton = styled.button`
    background: transparent;
    font-size: 36px;
    color: #fff;
    opacity: .2;
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 9999;
    &.active {
        opacity: 1;
    }
    &:hover {
        transform: scale(1.2);
    }
`

const LightboxModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.94);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
`
const LightboxContent = styled.div`
  
  width: 100%;
  height: 95vh;
  .gatsby-image-wrapper {
      height: 95vh;
      img {
          object-fit: contain !important;
      }
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
  button:first-child {
    margin-right: 10px;
  }
`


export default DailyPage;



// export const query = graphql`
// query {
//     allDatoCmsOverview {
//        edges {
//            node {
//                id
//                title
//                source
//                description
//                shopLink
//                category {
//                    name
//                }
//                slug
//                image {
//                    fluid(
//                        maxWidth: 2000
//                        imgixParams: {
//                            q: 85
//                            dpr: 3
//                            w: "2000"
//                            fit: "clip"
//                            auto: "compress"
//                        }
//                    ) {
//                        ...GatsbyDatoCmsFluid
//                    }
//                }
//                geo {
//                    latitude
//                    longitude
//                }
//            }
//        }
//     }
// }
// `;


