import React from "react"
import {Link, graphql } from "gatsby"
import styled from "styled-components"
import { Section} from "../../components/Theme"
import Img from "gatsby-image"
import Layout from "../../components/layout";
import Header from "../../components/Header";
import Footer from "../../components/footer.js";
import media from "styled-media-query";
import Tabs from "../../components/tabs.js";

// const PrintshopHeader = styled.div`
//     ${Grid};
//     min-height: 100vh;
// `
// const ProductRowHeader = styled(Text)`
//     margin-bottom: 60px;
//     font-size: 16px;
// `;
const ProductImage = styled.div`
    position: relative;
    overflow: hidden;
    ${media.greaterThan("large")`
        .hover-img {
            transition: all 200ms ease-in-out;
        }
        &:hover .hover-img {
            opacity: 1 !important;
        }
    `}
`;
const PageHeader = styled.h1`
    grid-column: 2  /11;
    padding: 60px 0 0 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
    `}
`;


const ProductWrapper = styled.div`
    grid-column: span 1;
    margin-bottom: 120px;
`;

const PrintsPage = ({ data }) => (
    <Layout parent="printshop-page">
        <div className="page-products">
            <Header content_color="black" />
            <Section>
                <PageHeader>Printshop</PageHeader>
            </Section>

            <Tabs>
                <div label="All Prints">
                    {data.allDatoCmsProduct.edges.map(({ node: product }) => (
                        <ProductWrapper key={product.id}>
                            <Link to={`/shop/${product.slug}`}>
                                <div className="product-item">
                                    <ProductImage>
                                        {
                                            product.productimg.map(img => (
                                                <Img fluid={img.fluid} alt="" />
                                            ))[0]
                                        }
                                        {
                                            product.productimg.map(img => (
                                                <Img
                                                    fluid={img.fluid}
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        opacity: 0
                                                    }}
                                                    className="hover-img"
                                                />
                                            ))[1]
                                        }
                                    </ProductImage>
                                    <h3 className="product-name">
                                        {product.title}
                                    </h3>
                                </div>
                            </Link>
                        </ProductWrapper>
                    ))}
                </div>
                <div label="New Releases">
                    {data.newreleases.edges.map(({ node: product }) => (
                        <ProductWrapper key={product.id}>
                            <Link to={`/shop/${product.slug}`}>
                                <div className="product-item">
                                    <ProductImage>
                                        {
                                            product.productimg.map(img => (
                                                <Img fluid={img.fluid} alt="" />
                                            ))[0]
                                        }
                                        {
                                            product.productimg.map(img => (
                                                <Img
                                                    fluid={img.fluid}
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        opacity: 0
                                                    }}
                                                    className="hover-img"
                                                />
                                            ))[1]
                                        }
                                    </ProductImage>

                                    <h3 className="product-name">
                                        {product.title}
                                    </h3>
                                </div>
                            </Link>
                        </ProductWrapper>
                    ))}
                </div>
                <div label="Popular">
                    {data.pop.edges.map(({ node: product }) => (
                        <ProductWrapper key={product.id}>
                            <Link to={`/shop/${product.slug}`}>
                                <div className="product-item">
                                    <ProductImage>
                                        {
                                            product.productimg.map(img => (
                                                <Img fluid={img.fluid} alt="" />
                                            ))[0]
                                        }
                                        {
                                            product.productimg.map(img => (
                                                <Img
                                                    fluid={img.fluid}
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        opacity: 0
                                                    }}
                                                    className="hover-img"
                                                />
                                            ))[1]
                                        }
                                    </ProductImage>
                                    <h3 className="product-name">
                                        {product.title}
                                    </h3>
                                </div>
                            </Link>
                        </ProductWrapper>
                    ))}
                </div>
            </Tabs>
        </div>
        <Footer />
    </Layout>
);

export default PrintsPage;

export const query = graphql`
           query PrintsPageQuery {
               datoCmsPrintshopPage {
                   featuredShopImage {
                       fluid {
                           ...GatsbyDatoCmsFluid
                       }
                   }
                   introText
               }
               allDatoCmsProduct(
                   filter: { hiddenProduct: { eq: false } }
                   sort: { order: ASC, fields: title }
               ) {
                   edges {
                       node {
                           title
                           id
                           productimg {
                               fluid(
                                   imgixParams: {
                                       h: "600"
                                       w: "600"
                                       fit: "crop"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                           }
                           caption
                           source
                           slug
                       }
                   }
               }

               prints: allDatoCmsProduct(
                   filter: { hiddenProduct: { eq: false } }
                   sort: { order: ASC, fields: title }
               ) {
                   edges {
                       node {
                           title
                           id
                           productimg {
                               fluid(
                                   imgixParams: {
                                       h: "600"
                                       w: "600"
                                       fit: "crop"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                           }
                           caption
                           source
                           slug
                       }
                   }
               }
               newreleases: allDatoCmsProduct(
                   filter: {
                       hiddenProduct: { eq: false }
                       tags: { eq: "New Releases" }
                   }
               ) {
                   edges {
                       node {
                           title
                           id
                           productimg {
                               fluid(
                                   imgixParams: {
                                       h: "600"
                                       w: "600"
                                       fit: "crop"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                           }
                           caption
                           source
                           slug
                       }
                   }
               }
               pop: allDatoCmsProduct(
                   filter: {
                       hiddenProduct: { eq: false }
                       tags: { eq: "Most Popular" }
                   }
               ) {
                   edges {
                       node {
                           title
                           id
                           productimg {
                               fluid(
                                   imgixParams: {
                                       h: "600"
                                       w: "600"
                                       fit: "crop"
                                   }
                               ) {
                                   base64
                                   aspectRatio
                                   width
                                   height
                                   src
                                   srcSet
                                   sizes
                               }
                           }
                           caption
                           source
                           slug
                       }
                   }
               }
           }
       `;
