import React from "react";
import {Link, graphql } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import Layout from "../components/layout";
import Header from "../components/Header";

import Footer from "../components/footer.js";
import Text, {Section} from "../components/Theme";
import media from "styled-media-query";

const SectionProjects = styled(Section)`
  padding-top: 15vh;
  padding-bottom: 15vh;
`;

const CollabSub = styled(Text)`
  padding-top: 20px;
  margin-bottom: 60px;
  ${media.greaterThan("medium")`
  padding-top: 180px;
  `}
`;

const CollaborationsPage = ({ data }) => (

    <Layout parent="collaborations-page">
        <div className="page-collaborations">
            <Header  content_color="black"/>

                
            <Section large>
                <Text ultra col="2/12">Projects</Text>
                <Text body col="2/12">Explore select collaborations  <br />and projects that we’re proud of:<br /><br /></Text>
                <Text caption col="2/12"><Link to="contact">Partner With Us &rarr;</Link></Text>


            </Section>
                <Section>
                 

                    <div class="w-layout-grid partner-subgrid">
                        {data.allDatoCmsCollaboration.edges.map(({ node: collaboration }) => (
                                <div className="partner-block w-inline-block">
                                    <Link
                                        to={`/collaborations/${collaboration.slug}`}
                                    >

                                        <Img fluid={collaboration.featuredImage.fluid} />
                                        <div className="partnership-text">
                                            <p className="subheader partnership-name">
                                                {collaboration.title}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                </Section>

        </div>
        <Footer />
    </Layout>
);

export default CollaborationsPage;



  export const query = graphql`
    query CollaborationQuery {
        allDatoCmsCollaboration(sort: { fields: position }) {
            edges {
            node {
                title
                slug
                featuredImage {
                        fluid(imgixParams: { h: "400", w: "600", fit: "crop"}) {
                            ...GatsbyDatoCmsFluid
                        }

                    }
            }
            }
        }
    }
`;
