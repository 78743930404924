import React from "react";


const Info = props => (
<svg width="31px" height="31px" viewBox="0 0 31 31" fill={props.color} className="info-icon">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage-Copy-2" transform="translate(-1339.000000, -830.000000)" fill={props.color} fillRule="nonzero">
            <g id="Group" transform="translate(1339.000000, 830.000000)">
                <path d="M13.0571429,13.6428571 L13.0571429,12.5857143 L16.4571429,12.5857143 L16.4571429,18.7285714 L19.1142857,18.7285714 L19.1142857,19.7857143 L12.7714286,19.7857143 L12.7714286,18.7285714 L15.2571429,18.7285714 L15.2571429,13.6428571 L13.0571429,13.6428571 Z M14.7857143,10.6714286 C14.7857143,10.1142857 15.2285714,9.67142857 15.7714286,9.67142857 C16.3285714,9.67142857 16.7571429,10.1142857 16.7571429,10.6714286 C16.7571429,11.2142857 16.3285714,11.6428571 15.7714286,11.6428571 C15.2285714,11.6428571 14.7857143,11.2142857 14.7857143,10.6714286 Z M15.5,30.5 C7.21572875,30.5 0.5,23.7842712 0.5,15.5 C0.5,7.21572875 7.21572875,0.5 15.5,0.5 C23.7842712,0.5 30.5,7.21572875 30.5,15.5 C30.5,23.7842712 23.7842712,30.5 15.5,30.5 Z M15.5,29.0714286 C22.995293,29.0714286 29.0714286,22.995293 29.0714286,15.5 C29.0714286,8.00470697 22.995293,1.92857143 15.5,1.92857143 C8.00470697,1.92857143 1.92857143,8.00470697 1.92857143,15.5 C1.92857143,22.995293 8.00470697,29.0714286 15.5,29.0714286 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
);

export default Info;

