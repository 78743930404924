import React, { Component } from "react";
import { Section } from "./Theme";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tab from "./tab.js";
import media from "styled-media-query";
import { Link } from "gatsby";


 const ProductGrid = styled.div`
     grid-column: 2/12;
     grid-template-columns: 1fr 1fr 1fr;
     display: grid;
     grid-column-gap: 50px;
     ${media.lessThan("medium")`
        display: block;
        grid-column: 1/13;
    `}

     h2 {
         margin-bottom: 60px;
     }
 `;
const TabsSection = styled(Section)`
    ${media.lessThan("large")`    
        padding-top: 0;
    `}
`;
const TabbedHeader = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    grid-column: 2/11;
    li {
        display: inline-block;
        margin: 0 30px 0 0;
        padding: 0;
        list-style-type: none;
        opacity: 0.4;
        font-size: 16px;
        cursor: pointer;
        &:hover,
        &.tab-list-active {
            opacity: 1;
        }
        &.tab-list-active span,
        &:hover span {
            
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }
        ${media.lessThan("large")`
            display: block;
            margin: 0;
        `}
    }
`;

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label
        };
    }

    onClickTabItem = tab => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab }
        } = this;

        return (
          <>
            <TabsSection small className="tabs">
              <TabbedHeader className="tab-list">
                {children.map(child => {
                  const { label } = child.props;

                  return (
                    <Tab
                      activeTab={activeTab}
                      key={label}
                      label={label}
                      onClick={onClickTabItem}
                    />
                  );
                })}
                <li className="tab-list-item">
                    <Link to="/custom-order" className="caption">Custom Prints</Link>
                </li>
              </TabbedHeader>
            </TabsSection>

            <Section className="tab-content">
              <ProductGrid>
                {children.map(child => {
                  if (child.props.label !== activeTab) return undefined;
                  return child.props.children;
                })}
              </ProductGrid>
            </Section>
          </>
        );
    }
}

export default Tabs;
