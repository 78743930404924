import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Text, { Grid, Section } from "./Theme";
import media from "styled-media-query";
import Logo from "./Header/logo";
// import Mark from "./Header/mark";

const FooterWrapper = styled.div`
    background-color: #e0e0e0;
`;

const FooterBottomGrid = styled(Section)`
    ${media.lessThan("large")`
        display: grid !important;
    `}
`;
const FooterBottom = styled.div`
grid-column: 2/12;
background-color: rgba(0,0,0,.05);
padding: 30px 0; 

h3 {
        font-size: 12px;
        opacity: .4;
        margin: 0;
        padding: 0;
    }

`;
const FooterLogo = styled.div`
    grid-column: 11/12;
    ${media.lessThan("large")`
    grid-column: 1/5;
    grid-row: 2;
    padding-top: 15px;
    `}

`;
const FooterGridCol = styled.div`
    padding-top: 0px;
    padding-bottom: 0;
    padding: 80px 0;
    margin-bottom: 0px;
    align-self: start;
    text-align: left;
    max-width: 100%;
    &:nth-child(1) {
        ${media.greaterThan("medium")`
        grid-column: 2/4;
        `}
        grid-column: 1/6;
        padding: 0;
    }
    &:nth-child(2) {
        ${media.greaterThan("medium")`
            grid-column: 4/6;
        `}
        grid-column: 6/12;
        padding: 0;
    }
    &:nth-child(3) {
        
        ${media.greaterThan("medium")`
            grid-column: 6/8;
            padding: 0;
        `}
        grid-column: 1/6;
        padding: 25px 0 0 0;
    }
    &:nth-child(4) {
        grid-column: 1/12;
        padding: 25px 0 0 0;
        input {
            font-size: 18px;
        }
        ${media.greaterThan("medium")`
        grid-column: 8/12;
        padding: 0;
        input {
            font-size: 24px;
        }
        `}
    }
    h3 {
        opacity: .5;
    }
    form {
        width: 100%;
        input {
            height: 48px;
            width: 100%;
            background-color: transparent;
            border: 0px;
            border-bottom: 2px solid rgba(0,0,0,.1);
            font-family: nhp;
            margin-top: 0px;
            font-size: 24px;
            &::placeholder {
                color: #000;
            }
            &:focus::placeholder {
                color: #ccc;
            }

        }
        input[type="submit"] {
            display: none;
        }
    }
   
`;



const FooterGrid = styled.div`
    ${Grid};
    padding-top: 0;
    padding-bottom: 0;
    ${media.lessThan("medium")`
        
        display: grid !important;
        padding: 50px 25px ;
    `}
    
    ${media.greaterThan("medium")`
        font-size: 16px;
        line-height: 24px;
        
    `}
    
    align-content: start;
    justify-content: start;
    padding: 60px 0;
    grid-auto-flow: row;
    
    ul {
        grid-column: span 4;
        padding: 0;
        margin: 0;
        align-self: start;
    }
    p {
        font-size: 18px;    
    }

`;

const Footer = () => (
    <FooterWrapper>
        <FooterGrid>
            <FooterGridCol>
                <Text caption>Explore</Text>
                <Text sub>
                    <Link className="main-nav-link" to="/daily">
                        Daily
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/digital-index">
                        Index
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/map">
                        Map
                    </Link>
                </Text>
            </FooterGridCol>
            <FooterGridCol>
                <Text caption>
                    <Link to="/shop/">Shop</Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/shop/prints">
                        Prints
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/shop/books">
                        Books
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/shop/calendar">
                        Calendar
                    </Link>
                </Text>
            </FooterGridCol>
            <FooterGridCol>
                <Text caption><Link to="/about/">About</Link></Text>
                <Text sub>
                    <Link className="main-nav-link" to="/collaborations">
                       Projects
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/license">
                        License
                    </Link>
                </Text>
                
                <Text sub>
                    <Link className="main-nav-link" to="/contact">
                        Contact
                    </Link>
                </Text>
                
            </FooterGridCol>
            <FooterGridCol>
                <Text caption>Subscribe</Text>
                <form action="https://www.getrevue.co/profile/dailyoverview/add_subscriber" method="post" id="revue-form" name="revue-form"  target="_blank">
                                        <input
                                            className="revue-form-field" placeholder="Enter your email to subscribe &#8594;" type="email" name="member[email]" id="member_email"
                                        />
                                        <input type="submit" value="" name="member[subscribe]" id="member_submit"></input>  
                                    </form>

            </FooterGridCol>
        </FooterGrid>
        <FooterBottom>
            <FooterBottomGrid>
                <Text caption col="2/6">Copyright 2019 Overview</Text>
                <FooterLogo>
                    <Logo color="black" />
                </FooterLogo>
            </FooterBottomGrid>
        </FooterBottom>
    </FooterWrapper>
);

export default Footer;

