import React from "react";
import styled from "styled-components"
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Text from "../components/Theme";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import ResponsiveEmbed from 'react-responsive-embed';
import Footer from "../components/footer.js";
import Header from "../components/Header";
import media from "styled-media-query";
import ReactCompareImage from "react-compare-image";
import Plx from "react-plx";

const ParallaxContainer = styled.div`
  overflow: hidden;
`;

const ImgCaption = styled(Text)`
  text-align: center;
    font-size: 11px;
    margin-top: 20px;
    padding: 0px 200px 80px 200px;
    opacity: 0.5;
    line-height: 18px;

`;
const PageHeader = styled.h1`
    grid-column: 2/8;
    padding: 40px 0 0 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
    `}
`;
const scale = [
    {
        start: 0,
        end: 1200,
        properties: [
            {
                startValue: 1,
                endValue: 2,
                property: "scale"
            }
        ]
    }
];

const transY = [
    {
        start: 'self',
        end: '100%',
        properties: [
            {
                startValue: 100,
                endValue: 0,
                property: "translateY"
            }
        ]
    }
];

export default ({ data }) => (
    <Layout>
        <Header content_color="black" />
        <div className="story-template">
            <div className="main-grid story-grid">
                <PageHeader>{data.datoCmsStory.title}</PageHeader>
                <Text body col="2/12">
                    {data.datoCmsStory.subtitle}
                </Text>
                <div className="story-featured-img">
                    <Plx parallaxData={scale}>
                        <Img
                            fluid={data.datoCmsStory.featuredImage.fluid}
                            alt={data.datoCmsStory.title}
                        />
                    </Plx>
                </div>
                {data.datoCmsStory.featuredImage.title && (
                    <ImgCaption small>
                        {data.datoCmsStory.featuredImage.title}
                    </ImgCaption>
                )}
            </div>
        </div>

        <div className="main-grid story-grid">
            {data.datoCmsStory.content.map(block => (
                <div className={`block ${block.model.apiKey}`} key={block.id}>
                    {block.model.apiKey === "text_block" && (
                        <div
                            className="body-copy-medium"
                            dangerouslySetInnerHTML={{ __html: block.text }}
                        ></div>
                    )}

                    {block.model.apiKey === "video_embed" && (
                        <div className={"content-video"}>
                            <div className={"content-video__wrapper"}>
                                {block.url.provider === "youtube" ? (
                                    <ResponsiveEmbed
                                        src={`//www.youtube.com/embed/${block.url.providerUid}`}
                                        ratio={`${block.url.width}:${block.url.height}`}
                                        allowFullScreen
                                    />
                                ) : (
                                    <ResponsiveEmbed
                                        src={`//player.vimeo.com/video/${block.url.providerUid}?title=0&byline=0&portrait=0`}
                                        ratio={`${block.url.width}:${block.url.height}`}
                                        allowFullScreen
                                    />
                                )}
                                 <ImgCaption small>
                                    {block.caption}
                                </ImgCaption>
                            
                            </div>
                        </div>
                    )}

                    {block.model.apiKey === "media_one_column" && (
                        <>
                            <ParallaxContainer>
                                <Plx parallaxData={transY}>
                                    <Img
                                        fluid={block.image.fluid}
                                        alt={data.datoCmsStory.title}
                                    />
                                </Plx>
                            </ParallaxContainer>

                            {block.image.title && (
                                <ImgCaption small>
                                    {block.image.title}
                                </ImgCaption>
                            )}
                        </>
                    )}
                    {block.model.apiKey === "media_two_column" && (
                        <div>
                            {block.imageRowBlock.map(img => (
                                <Img
                                    fluid={img.fluid}
                                    alt={data.datoCmsStory.title}
                                />
                            ))}
                        </div>
                    )}
                    {block.model.apiKey === "image_comparison_block" && (
                        <>
                            <ReactCompareImage
                                leftImage={block.oldImage.url}
                                rightImage={block.newImage.url}
                            />
                            <ImgCaption small>
                                {block.caption}
                            </ImgCaption>
                        </>
                    )}
                </div>
            ))}
        </div>

        <Footer />
    </Layout>
);

export const query = graphql`
           query singleStoryQuery($slug: String!) {
               datoCmsStory(slug: { eq: $slug }) {
                   id
                   title
                   subtitle
                   slug
                   category {
                       name
                   }
                   featuredImage {
                       fluid(imgixParams: { fm: "jpg" }) {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   content {
                       ... on DatoCmsTextBlock {
                           model {
                               apiKey
                           }
                           text
                       }
                       ... on DatoCmsMediaOneColumn {
                           model {
                               apiKey
                           }
                           image {
                               fluid(
                                   maxWidth: 1600
                                   imgixParams: {
                                       dpr: 1
                                       w: "1600"
                                       fit: "clip"
                                       auto: "compress"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                               title
                           }
                       }
                       ... on DatoCmsMediaTwoColumn {
                           model {
                               apiKey
                           }

                           imageRowBlock {
                               fluid(
                                   maxWidth: 1600
                                   imgixParams: {
                                       dpr: 1
                                       w: "1600"
                                       fit: "clip"
                                       auto: "compress"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                               title
                           }
                       }
                       ... on DatoCmsImageComparisonBlock {
                           model {
                               apiKey
                           }
                           newImage {
                               url
                           }
                           oldImage {
                               url
                           }
                           caption
                       }
                       ... on DatoCmsVideoEmbed {
                           model {
                               apiKey
                           }
                           url {
                               url
                               title
                               provider
                               providerUid
                               thumbnailUrl
                               width
                               height
                           }
                           caption
                       }
                   }
               }
           }
       `;
