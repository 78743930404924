import React, { Component } from "react";
import styled from "styled-components"
import PropTypes from "prop-types";
// import { motion } from "framer-motion";
import { Link } from "gatsby";
// import Img from "gatsby-image";
import Text, {Grid} from "../components/Theme";
import Layout from "../components/layout";
import Header from "../components/Header";
import algoliasearch from 'algoliasearch/lite';
// import { orderBy } from 'lodash';
import media from "styled-media-query";
import {
    InstantSearch,
    Hits,
    SearchBox,
    Pagination,
    Highlight,
    // RefinementList,
    connectRefinementList,
    Configure,
} from 'react-instantsearch-dom';

import qs from "qs";

import withLocation from "../components/Router/withLocation.js"; 

import Footer from "../components/footer.js";
const FilterToggle = styled.label`
  grid-row: 2/3;
  grid-column: 2/3;
  margin-bottom: 10px;
  ${media.lessThan("large")`
      grid-column: 1/2;
  `}
`;
const IndexHeader = styled(Text)`
   ${media.lessThan("large")`
   grid-column: 1/12;
   `}
`;
const SearchLabel = styled.div`
  
  ${media.lessThan("large")`
    margin-bottom: 10px;
    margin-top: 40px;
    grid-column: 1/2;
  `}
`;
const ArchiveList = styled.ol`
    grid-column: 2/12;
    padding-left: 25px;
    &:hover li {
        opacity: 0.4;
        &:hover {
            opacity: 1;
        }
    }
    
`;
const ArchiveListItem = styled.div `
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 30px 0px;
    border-bottom: 1px solid #000;
    font-family: am, sans-serif;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    
    
    &:hover  {
        opacity: 1;
        .hover-img  {
            opacity: 1;
            pointer-events: none;
            z-index: 999;
        }
    }
`

const StaticRefinementList = connectRefinementList(
    ({ values, currentRefinement, items, refine }) => (
      <ul className="ais-RefinementList">
        {values.map(staticItem => {
          const { isRefined } = items.find(
            item => item.label === staticItem.label
          ) || {
            isRefined: false,
          };
  
          return (
            <li key={staticItem.value}>
              <label className="ais-RefinementList-label">
                <input
                  type="checkbox"
                  className="ais-RefinementList-checkbox"
                  value={staticItem.value}
                  checked={isRefined}
                  onChange={event => {
                    const value = event.currentTarget.value;
                    const next = currentRefinement.includes(value)
                      ? currentRefinement.filter(current => current !== value)
                      : currentRefinement.concat(value);
  
                    refine(next);
                  }}
                />
                <span>{staticItem.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    )
  );

const searchClient = algoliasearch(
    'AI7O5IJ8D5',
    '7f1a509e834f885835edcfd3482b990c'
    
);

const SearchHeader = styled.div`
    ${Grid};
    margin-bottom: 40px;
    grid-row-gap: 10px;
    #filter_toggle {
                    visibility: hidden;
                    position: absolute;
                }
    h2 {
        margin: 80px 0 60px 0;
    }

        ul.ais-RefinementList { 
            margin: 0;
            padding: 0;
            grid-column: 3/9;
            grid-row: 2/3;
            align-self: start;
            display: flex;
            justify-content: space-between;
            ${media.lessThan("large")`
                grid-column: 1/13;
                grid-row: 3/4;
                flex-wrap: wrap;
                justify-content: start;
            `}
            ${media.lessThan("medium")`
                grid-column: 1/13;
                grid-row: 3/4;
            `}
            
            li {
                font-family: "am";
                text-transform: uppercase !important;
                font-weight: 100 ;
                margin: 0;
                padding: 0;
                text-align: left;
                label {
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }
                
                span {
                    font-weight: 100;
                }
                label {
                    margin: 0 30px 0 0;
                    padding: 0;
                    
                }
                input[type=checkbox]{
                    display: none;
                }
                input[type=checkbox]{
                    display: none;
                }
            }

        }
    }
    li {
        list-style: none;
        display: inline-block;
        label {
            input {
                &:checked + span, &:hover + span {
                    opacity: 1;
                    border-bottom: 1px solid rgba(0,0,0,.5);
                }
            }
            span {
                opacity: .4;
                display: inline-block;
            }

        }
        
        &.ais-RefinementList-item--selected {
            opacity: 1;
            text-decoration: underline;
        }
        &:hover {
            opacity: 1;
        }
    }
    .ais-RefinementList-count {
        display: none;
    }
    .ais-SearchBox {
        grid-column: 3/8;
        grid-row: 3/4;

        align-self: start;
        text-transform: uppercase;
        ${media.lessThan("large")`
                grid-column: 1/13;
                grid-row: 4/5;
        `}
        input {
            border: 0;
            font-family: "am";
            text-transform: uppercase !important;
        }
    }
`;



const DEBOUNCE_TIME = 400;
const createURL = state => `?${qs.stringify(state)}`;

const urlToSearchState = location => qs.parse(location.search.slice(1));

class ArchivePage extends Component {
     /* eslint-disable react/destructuring-assignment */
     state = {
        searchState: urlToSearchState(this.props.location),
        lastLocation: this.props.location,
    }
    /* eslint-enable react/destructuring-assignment */

    static getDerivedStateFromProps(props, state) {
        if (props.location !== state.lastLocation) {
            return {
                searchState: urlToSearchState(props.location),
                lastLocation: props.location,
            };
        }

        return null;
    }

    onSearchStateChange = (searchState) => {
        clearTimeout(this.debouncedSetState);

        this.debouncedSetState = setTimeout(() => {
            const { navigate } = this.props;
            navigate(createURL(searchState));

            // navigate.push(
            //     searchStateToUrl(this.props, searchState),
            //     searchState,
            // );
        }, DEBOUNCE_TIME);

        this.setState({ searchState });
    };
    render() {

        const { state, onSearchStateChange } = this;
        return (
            <Layout parent="archive-page">
                <Header content_color="black" />
                
                <InstantSearch 
                indexName="overview" 
                searchClient={searchClient} 
                searchState={state.searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
                className="archive-list">
                        <SearchHeader>
                            <IndexHeader sub col="2/12" mobileCol="1/13">Overview Index</IndexHeader>
                            <input type="checkbox" id="filter_toggle" />
                            <FilterToggle for="filter_toggle" className="caption">Filter:</FilterToggle>
                            
                            <StaticRefinementList
                            attribute="_tags.name"
                            values={[
                                { label: 'Food', value: 'Food' },
                                { label: 'Mining', value: 'Mining' },
                                { label: 'Energy', value: 'Energy' },
                                { label: 'Cities', value: 'Cities' },
                                { label: 'Design', value: 'Design' },
                                { label: 'Transport', value: 'Transport' },
                                { label: 'Leisure', value: 'Leisure' },
                                { label: 'Waste', value: 'Waste' },
                                { label: 'Nature', value: 'Nature' },
                            ]}
                            />
                            {/* <RefinementList attribute="_tags.name" transformItems={items => orderBy(items, "label", "asc")} /> */}
                            <SearchLabel className="caption archive-search-label">Search:</SearchLabel>
                            <SearchBox />
                        </SearchHeader>

                        <Configure hitsPerPage={30} />
              
                    
                    <div className="w-layout-grid main-grid overview-index">
                        
                        <ArchiveList id="w-node-e32af32b47af-c6b3f613" className="overview-index-list w-list-unstyled">
                            <Hits hitComponent={Hit} />    
                        </ArchiveList>
                                
                  
                        <Pagination />
                    </div>
                    
                    </InstantSearch>    
                    <Footer />
            </Layout>  
        )
    }
}

export default withLocation(ArchivePage);


function Hit(props) {
    return (
        <Link to={`/overviews/${props.hit.slug}`}>
            <ArchiveListItem className="overview-index-item">
                <div className="hover"></div>
                <img
                    src={`${props.hit.image.url}?auto=compress%2Cformat&fit=crop&w=400&h=400&dpr=2`}
                    align="left"
                    alt={props.hit.title}
                    className="hover-img"
                />

                <div className="results-location">
                    <Highlight attribute="title" hit={props.hit} />
                </div>

                <div className="results-geo">
                    {props.hit._geoloc.lat.toFixed(6)}°,{" "}
                    {props.hit._geoloc.lng.toFixed(6)}°
                </div>
            </ArchiveListItem>
        </Link>
    );
  }

Hit.propTypes = {
hit: PropTypes.object.isRequired,
};

