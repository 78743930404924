import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Text, { Grid } from "../../Theme";
import media from "styled-media-query";

const NavGridCol = styled.div`
    padding-top: 30px;
    margin-bottom: 30px;
    align-self: start;
    grid-column: span 4;
    text-align: left;
    max-width: 100%;
`;



const NavGrid = styled.div`
    ${Grid};
    ${media.lessThan("medium")`
        display: block;
        padding: 0 30px;
    `}
    
    ${media.greaterThan("medium")`
        font-size: 16px;
        line-height: 24px;
        height: 100vh;
    `}
    grid-template-rows: 1fr 125px;
    align-content: start;
    justify-content: start;
    grid-auto-flow: row;
    ul {
        grid-column: span 4;
        padding: 0;
        margin: 0;
        align-self: start;
    }

`;

const Nav = () => (
    <NavGrid>
        <NavGridCol>
            <Text sub>Explore</Text>
            <Text ultra>
                <Link className="main-nav-link" to="/daily">
                    Daily
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/digital-index">
                    Index
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/map">
                    Map
                </Link>
            </Text>
        </NavGridCol>
        <NavGridCol>
            <Text sub><Link to="/shop">Shop</Link></Text>
            <Text ultra>
                <Link className="main-nav-link" to="/shop/prints">
                    Prints
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/shop/books">
                    Books
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/shop/calendar">
                    Calendar
                </Link>
            </Text>
        </NavGridCol>
        <NavGridCol>
            <Text sub><Link to="/about">About</Link></Text>
            <Text ultra>
                <Link className="main-nav-link" to="/collaborations">
                   Projects
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/license">
                    License
                </Link>
            </Text>
            <Text ultra>
                <Link className="main-nav-link" to="/contact">
                    Contact
                </Link>
            </Text>
        </NavGridCol>

        <Text body col="span 4">
        <a href="https://www.instagram.com/dailyoverview/" target="_blank" rel="noopener noreferrer">Instagram</a>
        </Text>
        <Text body col="span 4">
        <a href="https://www.facebook.com/doverview/" target="_blank" rel="noopener noreferrer">Facebook</a>
        </Text>
        <Text body col="span 4">
            <a href="https://www.twitter.com/doverview/" target="_blank" rel="noopener noreferrer">Twitter</a>
        </Text>
    </NavGrid>
);

export default Nav;
