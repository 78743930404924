import React from "react";
import styled from "styled-components"
const LogoMark = styled.div `
    width: 25px;
    height: 25px;
    
    transition: 100ms ease-in transform;
    transform: scale(1.2);
    &:hover {
        transform: scale(1.5);
    }
`

const Mark = props => (
    <LogoMark>
        <svg width="25px" height="25px" viewBox="0 0 20 20">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Nav-Black" transform="translate(-263.000000, -30.000000)" fill={props.color}>
                    <path d="M272.999937,50 C267.485851,50 262.999937,45.5140861 262.999937,40 C262.999937,34.4859139 267.485851,30 272.999937,30 C278.514024,30 282.999937,34.4859139 282.999937,40 C282.999937,45.5140861 278.514024,50 272.999937,50 M272.999937,32.4182884 C268.819561,32.4182884 265.418226,35.8196233 265.418226,40 C265.418226,44.1803767 268.819561,47.5817116 272.999937,47.5817116 C277.180314,47.5817116 280.581336,44.1803767 280.581336,40 C280.581336,35.8196233 277.180314,32.4182884 272.999937,32.4182884" id="Fill-1"></path>
                </g>
            </g>
        </svg>
    </LogoMark>
);

export default Mark;

