import React, { Component } from "react";
import styled from "styled-components";
import { Section } from "../Theme";
import { Link } from "gatsby"
import media from "styled-media-query";

const SearchMainContainer = styled.div`
    background-color: #f1f1f1;
    overflow: hidden;
`;

const SearchSection = styled(Section)` 
    
    ${media.lessThan("large")`
        padding: 20px;
        display: grid;
        input {
            font-size: 18px;
            height: 40px;
            line-height: 40px;

        }
        
    `}
    input {
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        grid-column: 3/11;
    }
    a {
        display: none;
        
    }
    ${media.lessThan("large")`
        input {
            grid-column: 1/10;
        }
        a {
            display: block;  
            grid-column: 10/13;
            button {font-size: 24px; width: 100%; background: none; }
        } 
          
        `}
    
    .ais-Hits {
        ul {
            margin: 0;
            padding: 0;
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
`;


// import media from "styled-media-query";
// import HitComponent from './HitComponent'
// import searchClient from './client'

// const SearchSection = styled.div `
     
// `
// const SearchHeader = styled.h3`
//   font-size: 64px;
//   display: inline;
//   ${media.lessThan("large")`
//         font-size: 36px;
//         line-height: 30px;
//         height: 30px;
//         transform: translateY(-20px);
//         margin: 0;
//         padding: 0;
// `}
// `;
// const SearchContainer = styled.div `
//     display: block;
//     max-width: 1640px;
//         width: 100%;
//     margin-top: 0px;
//     margin-right: auto;
//     margin-left: auto;
//     padding-right: 50px;
//     padding-left: 50px;
//     padding-top: 60px;
// `

// // const HitGrid = styled.section `
// //     display: grid;
// //     max-width: 1640px;
// //         width: 100%;
// //     margin: 0px auto 40px auto;
// //     grid-column-gap: 50px;
// //     grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
// //     align-items: start;
    
// //     h3 {
// //         font-family: nhp;
// //         text-transform: none;
// //         grid-column: 2/12;
// //         margin: 0;
// //         padding: 10px 0;
// //         border-bottom: 2px solid #000;
// //         color: #000;
// //         span {
// //             font-size: 14px;
// //             opacity: .5;
// //             margin-left: 20px;
// //         }
// //     }
    
// //     a {
// //         opacity: .5;
// //         float: right;
// //         font-size: 14px;
// //     }
// // `

class AlgoliaSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
    }   


//   componentWillMount(){
//     const { indexes } = this.props
//     this.indexes = indexes
//       .map(i => searchClient.initIndex(i.name))
//   }

handleChange(event) {
    this.setState({value: event.target.value});
  }
  render() {
    // const { query } = this.state
    
    return (
        <>
        <form>
            <SearchMainContainer>
                <SearchSection large>
                    
                        <input
                    type="text"
                    value={this.state.value} 
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    className="searchinput headline"
                    />
                    <Link to={`/digital-index?query=${this.state.value}&page=1&configure%5BhitsPerPage%5D=30`}>
                    <button>&#8594;</button>
                    </Link>
                    
                </SearchSection>
            </SearchMainContainer>
        
        

            </form>
        
    </>
      
    )
  }
}

export default AlgoliaSearch
