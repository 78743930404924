import React from 'react';
import styled from "styled-components"
// import { Link } from "gatsby"
import Text, {Grid} from "./Theme"
import media from "styled-media-query"

const PrintName = styled.input`
  display: none;
`;

const ContactModalContainer = styled.div`
    background-color: #c56d64;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 9998;
    padding: 80px 0 0 0;
    br {
        display: none;
    }
    ${media.greaterThan("medium")`
        padding: 0;
        br {
        display: block;
        
        }
    `}
`;
const IntroText = styled.div`
    ${Grid};
    grid-column: 1/13;
    padding: 0 !important;
    margin-bottom: 30px;
    ${media.lessThan("large")`
        padding-top: 60px;
    `}
`;
const ContactModal = styled.form`
    ${Grid};
    input {
        background-color: none;
        border: 0px;
    }
`;

const FormText = styled(Text) `
    color: #fff;
    align-self: start;
    margin-bottom: 40px;
    .msg {
        align-self: start;
    }
    label {
        color: #fff;
        margin-top: 24px;

    }
    ${media.lessThan("medium")`
        margin-bottom: 0px;
        label {
            margin-top: 10px;
            margin-bottom: 0;
        }
    `}

    textarea::placeholder, input::placeholder {
        color: #fff;
        opacity:.2;
    }
    &:last-child {
        align-self: start;
    }
`
const FormActions = styled.ul`
    grid-row: 4;
    grid-column: 2/5;
    padding: 0px 0;
    
    margin: 0;
    input {
        background-color: #fff;
        border: 0;
        text-align: center;
        display: block;
        margin: 0;
        height: 100%;
            padding: 20px 0px;
        width: 100%;
        font-family: "am";
        text-transform: uppercase;
        text-align: center;
    }
`;

const Field = styled.input`
color: #fff;
border: 0;
background-color: transparent;
box-shadow: -150px 0 0px 0px #c56d64;
margin-bottom: 20px;
align-self: start;

&:focus {
    outline: none;
    box-shadow: 0px 0 0px 0px #c56d64;
}
`
const Area = styled.textarea`
color: #fff;
border: 0;
background-color: transparent;
box-shadow: -180px 0 0px 0px #c56d64;
align-self: start;
&:focus {
    outline: none;
    box-shadow: 0px 0 0px 0px #c56d64;
}
`
const Label = styled.label`
    margin-top: 20px;
`


const PrintUnavailable = props => (
    <ContactModalContainer>
        <ContactModal
            name="product"
            method="POST"
            action="https://formspree.io/mrggjpja"
        >
            <IntroText>
                <FormText sub col="2/12">
                    We don't have {props.name} in our Printshop yet. <br />
                    If you’d like to purchase it, get in touch here:
                </FormText>
            </IntroText>

            <p className="hidden">
                Don’t fill this out if you're human:{" "}
                <input type="hidden" name="bot-field" value="contact" />
                <input type="hidden" name="form-name" value="contact" />
            </p>

            <FormText sub col="1/2">
                <Label className="caption">Name</Label>
            </FormText>
            <FormText sub col="2/6">
                <Field type="text" name="Name: " placeholder="Your Name" />
            </FormText>
            <FormText sub col="6/7">
                <Label className="caption">Email</Label>
            </FormText>
            <FormText sub col="7/12">
                <Field type="email" name="email" placeholder="Email" />
            </FormText>

            <FormText sub col="1/3" row="3/3">
                <Label className="caption">Size</Label>
            </FormText>
            <FormText sub col="2/6" row="3/3">
                <PrintName
                    type="text"
                    name="Requested Print: "
                    value={props.name}
                />
                <Field
                    type="text"
                    name="Print Size"
                    placeholder="Requested Size"
                />
            </FormText>
            <FormText sub col="6/7" row="3/3">
                <Label className="caption">Message</Label>
            </FormText>
            <FormText sub col="7/12" row="3/3">
                <Area name="Message: " placeholder="Your Message"></Area>
            </FormText>

            <FormActions>
                <input
                    type="submit"
                    value="Submit Request"
                    className="special"
                />
            </FormActions>
        </ContactModal>
    </ContactModalContainer>
);

export default PrintUnavailable;
