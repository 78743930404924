import React from "react"
import {Link, graphql } from "gatsby"
import styled from "styled-components"
import Text, { Section} from "../../components/Theme"
import Img from "gatsby-image"
import Layout from "../../components/layout";
import Header from "../../components/Header";
import Footer from "../../components/footer.js";
import media from "styled-media-query";

// const PrintshopHeader = styled.div`
//     ${Grid};
//     min-height: 100vh;
// `

const FeaturedProducts = styled(Section)`
  /* background-color: #eee; */
`;

 const ProductGrid = styled.div`
     grid-column: 2/12;
     grid-template-columns: 1fr 1fr;
     display: grid;
     grid-column-gap: 50px;
     ${media.lessThan("medium")`
        display: block;
        grid-column: 1/13;
    `}

     h2 {

         margin-bottom: 60px;
     }

 `;

const ProductWrapper = styled.div`
    grid-column: span 1;
    margin-bottom: 120px;
`;

const BooksPage = ({ data }) => (
    <Layout parent="printshop-page">
        <div className="page-products">
            <Header content_color="black" />
            <Section small>
                <Text col="2/12" mobileCol="1/13">
                    Bookshop
                </Text>
            </Section>

            <FeaturedProducts small>
                <ProductGrid>
                    <ProductWrapper>
                        <Link to="/book">
                            <div className="product-item">
                                <div className="gatsby-image-wrapper">
                                    <Img
                                        fluid={data.datoCmsBookLandingPage.regularBookImage.fluid}
                                        alt=""
                                    />
                                </div>
                                <h3 className="product-name">
                                    Overview:
                                    <br />A New Perspective of Earth
                                </h3>
                            </div>
                        </Link>
                    </ProductWrapper>

                    <ProductWrapper>
                        <Link to="/kids-book">
                            <div className="product-item">
                                <div className="gatsby-image-wrapper">
                                    <Img
                                        fluid={data.datoCmsBookLandingPage.kidsBookImage.fluid}
                                        alt=""
                                    />
                                </div>
                                <h3 className="product-name">
                                    Overview, Young Explorer's Edition:
                                    <br />A New Way of Seeing Earth
                                </h3>
                            </div>
                        </Link>
                    </ProductWrapper>
                </ProductGrid>
            </FeaturedProducts>
        </div>
        <Footer />
    </Layout>
);

export default BooksPage;

export const query = graphql`
           query BooksPageQuery {
               datoCmsBookLandingPage {
                   kidsBookImage {
                       fluid(
                           maxWidth: 900
                           imgixParams: {
                               dpr: 1
                               w: "900"
                               fit: "clip"
                               auto: "compress"
                           }
                       ) {
                           ...GatsbyDatoCmsFluid
                       }
                   }
                   regularBookImage {
                       fluid(
                           maxWidth: 700
                           imgixParams: {
                               dpr: 1
                               w: "700"
                               fit: "clip"
                               auto: "compress"
                           }
                       ) {
                           ...GatsbyDatoCmsFluid
                       }
                   }
               }
           }
       `;
