import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Nav from "./Nav";
import Headroom from "react-headroom";
import Close from "./close";
import Logo from "./logo";
import Mark from "./mark";


const HeaderWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const SiteHeader = styled.div`

    display: grid;
    width: 100%;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    grid-auto-flow: column;
    grid-column-gap: 15px;
    grid-template-columns:
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr)
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(
            1px,
            1fr
        )
        minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    align-items: center;
    align-content: center;
    height: 60px;
    position: relative;
    z-index: 999;

    @media only screen and (min-device-width: 813px) {
        max-width: 1640px;
        width: 100%;
        padding-right: 50px;
        padding-left: 50px;
        grid-column-gap: 50px;
        height: 125px;
    }
`;
const LogoLeft = styled.div`
    grid-column: 1/1;
    cursor: pointer;
    height: 18px;
    width: 18px;
    div,
    svg {
        width: 18px;
        height: 18px;
    }
    @media only screen and (min-device-width: 813px) {
        width: 25px;
        height: 25px;
        div {
            width: 25px;
            height: 25px;
        }
    }
`;

const ExpandedNav = styled.div`
    background-color: #e0e0e0;
`;
const LogoRight = styled.div`
    grid-column: 11 / 13;
    align-self: center;
    justify-self: right;
    height: 16px;
    svg {
        height: 16px;
        width: auto;
    }
    a {
        display: block;
    }

    @media only screen and (min-device-width: 813px) {
        grid-column: 11 / 13;
        align-self: center;
        justify-self: right;
        height: 20px;
        svg {
            height: 20px;
            width: auto;
        }
        a {
            display: block;
        }
    }
`;

class Header extends Component {
    state = {
        modal: false,
        hovered: false
    };
    setHover = () => this.setState({ hovered: true });
    cancelHover = () => this.setState({ hovered: false });

    openModal = () => {
        const { modal } = this.state;
        if (!modal) {
            this.setState({ modal: true });
        }
    };

    closeModal = () => {
        const { modal } = this.state;
        if (modal) {
            this.setState({ modal: false });
        }
    };

    render() {
        const { content_color } = this.props;
        return (
            <HeaderWrapper>
            <div>
                
                <Headroom>
                    <div className={`header-container header-${content_color}`}>
                        <div className="header-container-background">
                            <SiteHeader>
                                <LogoLeft
                                    onClick={this.openModal}
                                    className="show-link"
                                >
                                    <Mark color={content_color} />
                                </LogoLeft>
                                <div
                                    className={`menu-center menu-${content_color}`}
                                >
                                    <div className="menu-item current first">
                                        <div className="menu-item-wrapper w-dropdown">
                                            <div className="nav-menu-link w-dropdown-toggle">
                                                <div className="caption">
                                                    Explore
                                                </div>
                                            </div>
                                            <nav className="submenu">
                                                <Link
                                                    to="/daily"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Daily</span>
                                                </Link>
                                                <Link
                                                    to="/digital-index"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Index</span>
                                                </Link>
                                                <Link
                                                    to="/map"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Map</span>
                                                </Link>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="menu-item current first">
                                        <div className="menu-item-wrapper w-dropdown">
                                            <div className="nav-menu-link w-dropdown-toggle">
                                                <div className="caption">
                                                    <Link
                                                        to="/shop"
                                                    >
                                                    Shop
                                                    </Link>
                                                </div>
                                            </div>
                                            <nav
                                                className={`submenu drink-${content_color}`}
                                            >
                                                <Link
                                                    to="/shop/prints"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Prints</span>
                                                </Link>
                                                <Link
                                                    to="/shop/books"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Books</span>
                                                </Link>
                                                <Link
                                                    to="/shop/calendar"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Calendar</span>
                                                </Link>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="menu-item current first">
                                        <div className="menu-item-wrapper w-dropdown">
                                            <div className="nav-menu-link w-dropdown-toggle">
                                            <div className="caption">
                                                    <Link
                                                        to="/about"
                                                    >
                                                    About
                                                    </Link>
                                                </div>
                                            </div>
                                            <nav className="submenu">
                                                <Link
                                                    to="/collaborations"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Projects</span>
                                                </Link>
                                                <Link
                                                    to="/press"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                        <span>Press</span>
                                                </Link>
                                                <Link
                                                    to="/contact"
                                                    className="dropdown-link caption w-dropdown-link"
                                                >
                                                    <span>Contact</span>
                                                </Link>
                                            </nav>
                                        </div>
                                    </div>
                                    {/* <div className="menu-item current first">
                                        <div className="menu-item-wrapper w-dropdown">
                                            <div className="nav-menu-link w-dropdown-toggle">
                                                <div className="caption">Search</div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <LogoRight>
                                    <Link to="/">
                                        <Logo color={content_color} />
                                    </Link>
                                </LogoRight>
                            </SiteHeader>
                            {/* <Cart className="Header__summary snipcart-summary snipcart-checkout"><CartIcon /> 
                    
                </Cart>*/}
                        </div>
                    </div>
                </Headroom>
                {this.state.modal ? (
                    <ExpandedNav className="menu-modal">
                        <Nav />
                        <div className="close-modal" onClick={this.closeModal}>
                            <Close />
                        </div>
                    </ExpandedNav>
                ) : null}
                
            </div>
            </HeaderWrapper>
        );
    }
}

export default Header;
