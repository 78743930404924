import React, { Component } from 'react'
import styled from "styled-components"
import { graphql, Link } from 'gatsby'
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import Search from '../components/Header/Search';
import Layout from "../components/layout"
import Header from "../components/Header"
import MapComponent from '../components/MapComponent'
import PlacesSearch from '../components/MapComponent/PlacesSearch'

import Footer from "../components/footer.js";
// import media from "styled-media-query";


const MenuItem = styled.div `
    display: inline-block;
    color: {content_color};
    font-family: am, sans-serif;
    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    text-transform: uppercase;
`

class Locations extends Component {
  state = {
    markers: [],
    categories: [],
    center: { lat: 37.759703, lng: -122.428093 }
  }

  componentWillMount(){
    const markers = this.nodes = this.props.data.allDatoCmsOverview.edges
      .map(e => e.node)
    this.categories = markers.reduce((cats, node) => {
      node.category.forEach(cat => !cats.includes(cat.name) && cats.push(cat.name))
      return cats
    } , []).sort()
    this.setState({ markers })
  }

  onCategoryChange = ev => {
    const { id, checked } = ev.target
    const category = id.substr(4)

    if(category === 'All'){
      return this.setState({ categories: this.categories, markers: this.nodes })
    }

    let { categories } = this.state
    if(checked){
      categories.push(category)
    } else {
      categories = categories.filter(cat => cat !== category)
    }

    const markers = this.nodes.filter(n => {
      for(let i = 0; i < n.category.length; i++){
        if(categories.includes(n.category[i].name)){
          return true
        }
      }
      return false
    })

    return this.setState({ categories, markers })
  }

  onPlaceSelect = res => {
    geocodeByPlaceId(res.place_id)
      .then(results => getLatLng(results[0]))
      .then(center => console.log({center}) || this.setState({ center }))
      .catch(error => console.error('Error', error))
  }

  clearCategories = ev => this.setState({ categories: [], markers: this.nodes })

  render(){
    const { markers, categories, center } = this.state
    return (
        <Layout parent="map-page">
          <div className="page-locations">
          <Header  content_color="black">
                    <MenuItem><Link to="/daily" className="nav-menu-link">Daily</Link></MenuItem>
                    <MenuItem><Link to="/digital-index" className="nav-menu-link">Index</Link></MenuItem>
                    <MenuItem><Link to="/map" className="nav-menu-link">Map</Link></MenuItem>
                    <MenuItem><Search placeholder="Search" className="global-search search" /></MenuItem>
                </Header>

        <div className="location-search">
          <div className="map-container">
            <MapComponent
              center={center}
              nodes={markers} />
          </div>
          <div className="filters">



            <ul>
            <h4 className="caption">Filter by category</h4>

              <li className="input-row">
                <input
                  onChange={this.onCategoryChange}
                  type="checkbox"
                  id="chk-All"
                  disabled={categories === 'All'}
                />
                <label htmlFor="chk-All">All</label>
              </li>
              {this.categories.map(cat => (
                <li key={cat} className="input-row">
                  <input
                    onChange={this.onCategoryChange}
                    type="checkbox"
                    id={`chk-${cat}`}
                    checked={categories.includes(cat)}
                  />
                  <label htmlFor={`chk-${cat}`}>{cat}</label>
                </li>
              ))}
               <div>
              <PlacesSearch onPlaceSelect={this.onPlaceSelect} />
            </div>
            </ul>
          </div>
        </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default Locations

export const query = graphql`
query OverviewLocations {
  allDatoCmsOverview {
    edges {
      node {
        id
        title
        slug
        category {
          name
        }
        geo {
          lat: latitude
          lng: longitude
        }
        image {
          fluid(imgixParams: { h: "600", w: "600", fit: "crop"}) {
            srcSet
          }
        }
      }
    }
  }
}
`
