import React, { Component } from "react";
import styled from "styled-components";
import Text, { MaxGrid, Grid, Section } from "../components/Theme";
import HomeSearch from "../components/AlgoliaSearch/HomeSearch";
// import Carousel from "nuka-carousel";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";

import media from "styled-media-query";
// import {
//     // InstantSearch,
//     // Hits,
//     // SearchBox,
//     Highlight,
//     // Configure
// } from "react-instantsearch-dom";
import Header from "../components/Header";
import Info from "../components/info-icon.js";
import Footer from "../components/footer.js";

// const CarouselFrame = styled(Carousel)`
//     width: 100%;
//     li {
//         cursor: default;

//     }
//     .slider-frame {
//         width: 100%;
//     }
// `;

const IndexPageHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
`

const HeroContainer = styled.div`
    background-color: #000;
    width: 100%;
    height: 100vh;
    .slider-frame {
        width: 100%;
    }
`;

const HeroGrid = styled.div`
    ${MaxGrid};
    .hero-inner {
        ${Grid};
        grid-column: 1/13;
        grid-row: 1;
        min-height: 100vh;
        z-index: 9998;
        ${media.lessThan("large")`
            display: grid;
            align-items: center;
            padding-left: 0 !important;
            padding-right: 0 !important;
        `}
    }
    ${media.lessThan("large")`
            display: grid;
            padding-left: 0 !important;
            align-items: center;
            padding-right: 0 !important;
        `}
    .gatsby-image-wrapper {
        grid-column: 1/13;
        grid-row: 1;
        width: 100%;
        height: 100vh;
    }

`;

const FeaturedOverviewContainer = styled(Section)`
    grid-row-gap:50px;
`;

const FeaturedOverview = styled.div`
    grid-column: 2/6;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;
    h3 {
        opacity: .4;
        padding-top: 10px;
    }
`;

const FeaturedDesc = styled(Text)`
    grid-column: 6/12;
    grid-row: 3/4;
    padding-top: 20px;
    margin-bottom: 10px;  
    a {
        font-size: 11px;
        padding-top: 20px; 
        opacity: .4;
        &:hover {
            opacity: 1;
        }

    }  
`;

const CatList = styled.ul`
    grid-column: 2/12;
    padding: 40px 0 0 0;
    display: grid;
    justify-content: start;
    justify-items: start;
    align-items: start;
    align-content: start;
    grid-auto-flow: column;
    grid-auto-columns: minmax(1px, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 0px;
    grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    grid-template-rows: auto auto auto;




    li.footer-list-item {
        display: block;
        font-weight: 100;


        a {
            position: relative;
            font-size: 64px;
            &:after {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0;
                height: 3px;
                width: 0%;

                background-color: #000;
                transition: all 100ms ease-out;
                transition-delay: 100ms;
            }
            &:hover:after {
                width: 100%;
                transition: all 100ms ease-out;
                transition-delay: 0ms;
            }
        }
        span {
            font-family: "helvetica neue";
            font-weight: 100;
        }
        &:last-child {
            span {
                display: none;
            }
        }
    }
    ${media.lessThan("large")`

        display: block;
        li.footer-list-item a {
            font-size: 36px;
            line-height: 48px;
        }

    `}
`;

const SubscribeBox = styled.div`
    grid-column: 2/11;
    padding: 50px 0 0 0;

    input {
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;

        width: 100%;
        &::placeholder {
            opacity: 0.3;
        }
    }
`;


class IndexPage extends Component {
    state = {
        contentColor: "#ffffff",
        markers: [],
        center: { lat: 37.759703, lng: -122.428093 },

    };

    componentWillMount() {
        this.slides = this.props.data.allDatoCmsHome.edges[0].node.heroSlider;
        this.setState({ contentColor: this.slides[0].color });

        const markers = (this.nodes = this.props.data.allDatoCmsOverview.edges.map(
            e => e.node
        ));
        this.setState({ markers });
    }

    sliderAfterChange = current => {
        const contentColor = this.slides[current].color;
        this.setState({ contentColor });
    };


    render() {
        const { data } = this.props;
        const { contentColor } = this.state;
        // const { contentColor, markers, center } = this.state;
        const { node: pageData } = data.allDatoCmsHome.edges[0];

        return (
            <Layout>
                <div className="page-home" ref={div => (this.wrapper = div)}>
                    <IndexPageHeaderContainer>
                      <Header content_color={contentColor} />
                    </IndexPageHeaderContainer>
                    <HeroContainer>
                        {/* <CarouselFrame
                            width="100%"
                            transitionMode="fade"
                            autoplayInterval="3000"
                            pauseOnHover
                            wrapAround
                            slideWidth={1.0}
                            initialSlidewidth={1.0}
                            autoplay
                            renderCenterLeftControls={null}
                            renderCenterRightControls={null}
                        >  */}
                        {pageData.heroSlider.map((block, index) => (
                            <div key={`slide-${index}`}>
                                <Link
                                    to={`/overviews/${block.overview.slug}`}
                                    className="info-toast dark"
                                >
                                    <Info color={block.color} />
                                    <div className="info-container">
                                        <h4 className="body-copy-medium">
                                            {block.overview.title}
                                        </h4>
                                        <div className="body-copy-small">
                                            {block.overview.description}
                                        </div>
                                        <p
                                            className={`caption toast-geo ${block.color}`}
                                        >
                                            {block.overview.geo.latitude.toFixed(
                                                6
                                            )}
                                            °,{" "}
                                            {block.overview.geo.longitude.toFixed(
                                                6
                                            )}
                                            °
                                        </p>
                                    </div>
                                </Link>
                                <HeroGrid>
                                    <div className="hero-inner">
                                        <Text
                                            centerMobile
                                            ultra
                                            col="2/12"
                                            mobileCol="2/12"
                                            color={contentColor}
                                            dangerouslySetInnerHTML={{
                                                __html: block.text
                                            }}
                                        />
                                    </div>
                                    <Img
                                        fluid={block.image.fluid}
                                        loading="eager"
                                        alt={block.title}
                                        backgroundColor="#fff"
                                        placeholderStyle={{
                                            filter: "blur(10px)"

                                        }}
                                        fadeIn={false}
                                    />
                                </HeroGrid>
                            </div>
                        ))}
                        {/* </CarouselFrame>  */}
                    </HeroContainer>

                    <Section full className="intro-text-section">
                        <Text
                            sub
                            col="2/12"
                            dangerouslySetInnerHTML={{
                                __html: pageData.introText
                            }}
                        />
                    </Section>

                    <HomeSearch placeholder="Search the Overview Index" />

                    <Section full>
                        <Text caption col="2/12" mobileCol="1/13">
                            Explore by Topic
                        </Text>

                        <CatList>
                            {data.allDatoCmsCategory.edges.map(
                                ({ node: cat }) => (
                                    <li
                                        className="footer-list-item headline-lyon explore-link"
                                        key={cat.id}
                                    >
                                        <Link
                                            to={`/digital-index?refinementList%5B_tags.name%5D%5B0%5D=${cat.name}&page=1&configure%5BhitsPerPage%5D=30`}
                                            key={cat.id}
                                        >
                                            {cat.name}
                                        </Link>
                                    </li>
                                )
                            )}
                        </CatList>
                    </Section>

                    <Section small>
                        <Text col="2/6">
                            <img
                                src={require("../images/daily-logo.png")}
                                alt="daily overview"
                                className="daily-logo"
                            />
                        </Text>
                        <SubscribeBox>
                            <Text sub>{pageData.subscriptionCallout}</Text>
                            <Text sub>
                                <form
                                    action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                    method="post"
                                    id="revue-form"
                                    name="revue-form"
                                    target="_blank"
                                >
                                    <input
                                        className="revue-form-field"
                                        placeholder="Enter your email and hit enter to subscribe &#8594;"
                                        type="email"
                                        name="member[email]"
                                        id="member_email"
                                    />
                                    <input
                                        type="submit"
                                        value=""
                                        name="member[subscribe]"
                                        id="member_submit"
                                    ></input>
                                </form>
                            </Text>
                        </SubscribeBox>
                    </Section>

                    {data.allDatoCmsOverview.edges.map(({ node: overview }) => (
                        <FeaturedOverviewContainer
                            stacked
                            full
                            key={overview.id}
                        >
                            <div
                                id="w-node-bdf8efa2c87b-d6aaf2c3"
                                className="featured-img"
                            >
                                <Link to="/daily">
                                    <Img
                                        fluid={overview.image.fluid}
                                        alt={overview.title}
                                        style={{ maxHeight: "700px" }}
                                    />
                                </Link>
                            </div>
                            <FeaturedOverview>
                                <h2 className="subheader featured-overview">
                                    <Link to="/daily">{overview.title}</Link>
                                </h2>
                                <Text caption>
                                    {overview.geo.latitude.toFixed(6)}°,{" "}
                                    {overview.geo.longitude.toFixed(6)}°
                                </Text>
                            </FeaturedOverview>
                            <FeaturedDesc small>
                                {overview.description}
                                <Text caption><a href="https://www.instagram.com/dailyoverview/" target="_blank">View on Instagram &rarr;</a></Text>
                            </FeaturedDesc>
                        </FeaturedOverviewContainer>
                    ))}

                    {/*
                <div className="home-section">
                    <div className="w-layout-grid main-grid">
                        <h3
                            id="w-node-030cee6bbe92-d6aaf2c3"
                            className="caption section-header-home"
                        >
                            Recent Stories
                        </h3>

                        <div
                            id="w-node-030cee6bbe8c-d6aaf2c3"
                            className="partner-block center"
                        >
                            <img
                                src={require("../images/about-hero.jpg")}
                                className="photo-img"
                                alt=""
                            />

                            <h3 className="caption story-caption">Caption</h3>
                            <p className="body-copy">
                                Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia
                            </p>
                        </div>

                        <div
                            id="w-node-030cee6bbe94-d6aaf2c3"
                            className="partner-block center grid-3"
                        >
                            <img
                                src={require("../images/about-hero.jpg")}
                                className="photo-img"
                                alt=""
                            />

                            <h3 className="caption story-caption">Caption</h3>
                            <p className="body-copy">
                                Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia
                            </p>
                        </div>
                        <div
                            id="w-node-030cee6bbe9a-d6aaf2c3"
                            className="partner-block"
                        >
                            <img
                                src={require("../images/dov3.jpeg")}
                                width="1250"
                                className="photo-img"
                                alt=""
                            />

                            <h3 className="caption story-caption">Caption</h3>
                            <p className="body-copy">
                                Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia
                            </p>
                        </div>
                        <div
                            id="w-node-030cee6bbea0-d6aaf2c3"
                            className="partner-block grid-4"
                        >
                            <img
                                src={require("../images/dov3.jpeg")}
                                width="1250"
                                className="photo-img"
                                alt=""
                            />

                            <h3 className="caption story-caption">Caption</h3>
                            <p className="body-copy">
                                Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia
                            </p>
                        </div>
                    </div>
                </div>
                */}

                    <Section full stacked top>
                        {pageData.productModules.map(block => (
                            <div className="product-block" key={block.id}>
                                <Link to={block.url}>
                                    <Img
                                        fluid={block.image.fluid}
                                        className="product-img"
                                    />

                                    <div className="w-row">
                                        <div className="column-6 w-col w-col-8 w-col-small-8">
                                            <h3 className="body-copy product-text">
                                                {block.description}
                                            </h3>
                                        </div>
                                        <div className="column-4 w-col w-col-4 w-col-small-4">
                                            <img
                                                src={require("../images/arrow.png")}
                                                width="114"
                                                alt=""
                                                className="grid-right"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </Section>
                    {/*
                    <Section full stacked top>
                        <HomeMapContainer>
                            <MapComponent center={center} nodes={markers} />
                        </HomeMapContainer>

                        <Text
                            caption
                            col="2/4"
                            dangerouslySetInnerHTML={{
                                __html: pageData.mapCalloutTitle
                            }}
                        />
                        <Text
                            body
                            col="5/9"
                            dangerouslySetInnerHTML={{
                                __html: pageData.mapCalloutDescription
                            }}
                        />
                        <Link to="/map" className="right-arrow">
                            <img
                                src={require("../images/arrow.png")}
                                id="w-node-87346914587d-d6aaf2c3"
                                alt=""
                            />
                        </Link>
                    </Section> */}
                </div>
                <Footer />
            </Layout>
        );
    }
}

export default IndexPage;

export const query = graphql`
           query HomeQuery {
               datoCmsHome {
                   seoMetaTags {
                       tags {
                           tagName
                           content
                           attributes {
                               property
                               name
                               content
                           }
                       }
                   }
               }
               allDatoCmsHome {
                   edges {
                       node {
                           introText
                           subscriptionCallout
                           productModules {
                               ... on DatoCmsContent {
                                   model {
                                       apiKey
                                   }
                                   url
                                   description
                                   image {
                                       fluid(
                                           maxWidth: 900
                                           imgixParams: {
                                               dpr: 1
                                               w: "900"
                                               fit: "clip"
                                               auto: "compress"
                                           }
                                       ) {
                                           ...GatsbyDatoCmsFluid
                                       }
                                   }
                               }
                           }
                           heroSlider {
                               ... on DatoCmsSlide {
                                   model {
                                       apiKey
                                   }
                                   color
                                   text
                                   crop
                                   image {
                                       fluid(
                                           maxWidth: 2000
                                           imgixParams: {
                                               q: 85
                                               dpr: 3
                                               w: "2000"
                                               fit: "clip"
                                               auto: "compress"
                                           }
                                       ) {
                                           ...GatsbyDatoCmsFluid
                                       }
                                   }
                                   overview {
                                       title
                                       description
                                       slug

                                       geo {
                                           latitude
                                           longitude
                                       }
                                   }
                               }
                           }
                       }
                   }
               }

               allDatoCmsOverview(
                   #    sort: { order: DESC, fields: meta___createdAt }
                   limit: 1
               ) {
                   edges {
                       node {
                           id
                           originalId
                           description
                           title
                           category {
                               name
                           }
                           slug
                           image {
                               fluid(
                                   maxWidth: 2000
                                   imgixParams: {
                                       q: 85
                                       dpr: 3
                                       w: "2000"
                                       fit: "clip"
                                       auto: "compress"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                           }
                           geo {
                               latitude
                               longitude
                           }
                       }
                   }
               }

               allDatoCmsCategory(sort: { fields: position }) {
                   edges {
                       node {
                           name
                       }
                   }
               }
           }
       `;
