import React from "react";
import {Link, graphql } from "gatsby"
// import Img from "gatsby-image";
import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/footer.js";



const StoriesPage = ({ data }) => (
    <Layout>
        <div className="page-stories">
        <Header  content_color="black"/>
        <div className="recent-partners">





            <div className="story-container">
                <h3 className="caption story-header">All Stories</h3>
                    {data.allDatoCmsStory.edges.map(({ node: story }) => (
                            <div>
                                <Link to={`/stories/${story.slug}`}>
                                    <p className="subheader partnership-name">{story.title}</p>
                                </Link>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
);

export default StoriesPage;



  export const query = graphql`
    query StoryQuery {
        allDatoCmsStory {
            edges {
            node {
                title
                slug

            }
            }
        }
    }
`;
