import React from "react"
import {Link, graphql } from "gatsby"
import styled from "styled-components"
import Text, { Section} from "../components/Theme"
import Img from "gatsby-image"
import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/footer.js";

import media from "styled-media-query";

const PrintshopHeader = styled(Section)`
    .gatsby-image-wrapper {
        grid-column: 6/12;
        margin-top: 60px;
    }
    h1 {
        grid-column: 2/5;
        grid-row: 1/2;
        span {
            display: block;
            font-size: 24px;
            line-height: 36px;
        }
    }

`
const ProductCategoriesContainer = styled.div`

`;
const ProductCategories = styled(Section)`

`

// const ProductsHeroImg = styled.div`
//     grid-column: 7/12;
// `;

// const ProductGridHeaders = styled(Text)`
//   margin-bottom: 40px;
// `;
const ThreeColGrid = styled.div`
    display: grid;
    grid-column: 2/12;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    h2 {
        color: #000;
        margin-top: 20px;

    }
    ${media.lessThan("medium")`
    grid-column: 1/13;
    `}

`;

const ProductBlock = styled.div`
    grid-column: span 1;
    color: #fff;
    ${media.lessThan("medium")`
    grid-column: span 3;
    `}
`


// const FeaturedProducts = styled(Section)`
//   background-color: #eee;
// `;

//  const ProductGrid = styled.div`
//      grid-column: 2/12;
//      grid-template-columns: 1fr 1fr 1fr;
//      display: grid;
//      grid-column-gap: 50px;

//      h2 {

//          margin-bottom: 60px;
//      }
//      ${media.lessThan("medium")`
//         display: block;
//         grid-column: 1/13;
//     `}

//  `;

// const ProductWrapper = styled.div`
//     grid-column: span 1;
//     margin-bottom: 120px;
// `;

const PrintshopPage = ({ data }) => (
    <Layout parent="printshop-page">
        <div className="page-products">
            <Header content_color="black" />
            <div class="store-hero-container">

                <PrintshopHeader small>

                        <Text ultra col="2/5">Shop
                            <span>{data.datoCmsPrintshopPage.introText}</span>
                        </Text>






                        <Img
                            fluid={data.datoCmsPrintshopPage.featuredShopImage.fluid}
                            alt=""
                        />

                </PrintshopHeader>
            </div>
            <ProductCategoriesContainer>
                <ProductCategories small>
                    <ThreeColGrid>
                        <ProductBlock>
                            <Link to="/shop/prints">
                                <Img
                                    fluid={data.datoCmsPrintshopPage.printsBlockImage.fluid}
                                    alt=""
                                />
                                <Text sub>Prints</Text>
                            </Link>
                        </ProductBlock>
                        <ProductBlock>
                            <Link to="/shop/books">
                            <Img
                                    fluid={data.datoCmsPrintshopPage.booksBlockImage.fluid}
                                    alt=""
                                />
                                <Text sub>Books</Text>
                            </Link>
                        </ProductBlock>

                        <ProductBlock>
                        <Link to="/shop/calendar">
                                <Img
                                    fluid={data.datoCmsPrintshopPage.calendarBlockImage.fluid}
                                    alt=""
                                />
                            <Text sub>Calendar</Text>
                        </Link>
                    </ProductBlock>
                    </ThreeColGrid>
                </ProductCategories>
            </ProductCategoriesContainer>

        </div>
        <Footer />
    </Layout>
);

export default PrintshopPage;

export const query = graphql`
           query PrintshopQuery {
               datoCmsPrintshopPage {
                    introText
                   featuredShopImage {
                       fluid {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   printsBlockImage {
                    fluid {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   booksBlockImage {
                    fluid {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   calendarBlockImage {
                    fluid {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   introText
               }
           }
       `;
