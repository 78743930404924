import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import Highlight from './Highlight'

const HitContainer = styled.li `
    list-style: none;
    &:last-child a div.divider {
            display: none;
        }
    a {
        display: grid;
        max-width: 1640px;
        padding: 0 50px;
        width: 100%;
        margin: 0px auto 20px auto;
        grid-column-gap: 50px;
        grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
        align-items: start;
        

    }
`
const HitHeader = styled.h3 `
    grid-column: 2/5;
    font-size: 14px;
    align-self: start;
    margin: 0;
    padding: 0;
    color: #000;
`
const HitDivider = styled.div `
    height: 1px;
    background-color: #ddd;
    grid-column: 2/12;
    grid-row: 2/2; 
    margin-top: 20px;
    display: block;
`

export default ({ hit, index, query }) => {
  return (
    <HitContainer className="hit">
      <Link to={`${index.section}/${hit.slug}`}>
        <HitHeader className="caption">{hit.title}</HitHeader>
          <Highlight
            source={hit.description || hit.content}
            query={query}
            className="some-class"
            tag="span"
          />
          <HitDivider className="divider"/>
      </Link>
    </HitContainer>
  )
}
